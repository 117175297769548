@import "@/assets/scss/style.scss";

.c-language-picker {
  position: absolute;
  right: 24px;
  top: 156px;

  &__select {
    width: 200px;
  }
}
